.Post {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .left {
        flex: 5;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .left-title {
            font-size: 55px;
        }

        .messageBox {
            flex: 0 1 auto;
            display: flex;
            flex-direction: column;
            min-width: 350px;
            max-height: 700px;
            overflow: auto;


            .message {
                background-color: lightgrey;
                display: flex;
                flex-direction: column;

                textarea{
                    font-size: 18px;
                    resize: none;
                    pointer-events: none;
                }
            }
        }

        .controlPanel {
            width: 100%;
            height: 100%;


            button {
                margin-top: 10px;
                min-height: 50px;
            }
        }
    }

    .right {
        flex: 5;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        input, textarea{
            margin-top: 5px;
            margin-bottom: 5px;
        }

        input {
            width: 30%;
            min-height: 20px;
        }

        textarea{
            width: 50%;
            min-height: 200px;
            font-size: 18px;
            resize: none;
        }

        button {
            width: 15%;
            min-height: 40px;
        }
    }
}